import React from 'react';
import hand from '../img/icon/thumbs-up.svg';
import checkPoint from '../img/icon/checkPoint.svg';

const Advantage =()=> {
    return (
        <div className={'vacancy'}>
            <div>
                <h2 style={{paddingBottom: '3rem'}}>
                    Работая у нас Вы получаете
                </h2>
            </div>
            <div className={'blockHand'}>
            <div className={'hand'}>
                <div>
                <img width={'100px'} src={hand} alt=""/></div>
            </div>
            <div  className={'advan'}>
            <div className={'advanCard'}>
                <img src={checkPoint} className={'advanImg'} alt=""/>
                <h3 className={'advanHead'}>Спецодежда</h3>
                <p className={'advanText'}>Выдается бесплатная спецодежда</p>
            </div>
                <div className={'advanCard'}>
                    <img src={checkPoint} className={'advanImg'} alt=""/>
                    <h3 className={'advanHead'}>Жилье</h3>
                    <p className={'advanText'}>Иногородним предоставляется жилье</p>
                </div>
                <div className={'advanCard'}>
                    <img src={checkPoint} className={'advanImg'} alt=""/>
                    <h3 className={'advanHead'}>Гибкий график</h3>
                    <p className={'advanText'}>График работы гибкий. 5/2, 6/1, 7/0</p>
                </div>
                <div className={'advanCard'}>
                    <img src={checkPoint} className={'advanImg'} alt=""/>
                    <h3 className={'advanHead'}>Разные смены</h3>
                    <p className={'advanText'}>Смены дневные и ночные.</p>
                </div>
                <div className={'advanCard'}>
                    <img src={checkPoint} className={'advanImg'} alt=""/>
                    <h3 className={'advanHead'}>Вахтерная работа</h3>
                    <p className={'advanText'}>Возможна работы вахтенным способом</p>
                </div>
                <div className={'advanCard'}>
                    <img src={checkPoint} className={'advanImg'} alt=""/>
                    <h3 className={'advanHead'}>Зарплата</h3>
                    <p className={'advanText'}>Стабильные выплаты</p>
                </div>
            </div>
        </div>
        </div>
    );
};

export default Advantage;