import React from 'react';
import {Container,Nav,Navbar} from "react-bootstrap";

const Navibar = ()=> {

        return (
                <Navbar bg="light" variant="light">
                    <Container>
                        <Nav className="me-auto">
                            <Nav.Link href="#home">О компании</Nav.Link>
                            <Nav.Link href="#human">Персонал</Nav.Link>
                            {/*<Nav.Link href="#vacancy">Вакансии</Nav.Link>*/}
                            <Nav.Link href="#partners">Партнеры</Nav.Link>

                        </Nav>
                    </Container>
                </Navbar>
        );
};

export default Navibar;