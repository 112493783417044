import React from 'react';


const Footer =()=> {
    return (
        <div className={'footer'}>
            <div className={'footerBlock'}>
            <div className={'footerText'}><p>Аутсорсинг и лизинг персонала</p></div>
            <div className={'footerText'}><p>Ежедневно  с 10:00 до 21:00</p></div>
            <div className={'footerText'}><p style={{color:'#fff'}}>© 2022 | Тредовые ресурсы</p></div>
            </div>
        </div>
    );
};

export default Footer;