import './App.css';
import Navibar from "./components/Navibar";
import AppHeader from "./components/AppHeader";
import Header from "./components/Header";
import About from "./components/About";
// import Vacancy from "./components/Vacancy";
// import Rus from "./components/Rus";
import Human from "./components/Human";
import Partners from "./components/Partners";
import Advantage from "./components/Advantage";
import Contacts from "./components/Contacts";
import Footer from "./components/Footer";
function App() {
  return (
    <div>
        <AppHeader/>
        <Navibar/>
        <Header/>
        <About/>
        {/*<Vacancy/>*/}
        {/*<Rus/>*/}
        <Partners/>
        <Human/>
        <Advantage/>
        <Contacts/>
        <Footer/>
    </div>
  );
}

export default App;
