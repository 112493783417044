import React from 'react';

const About =()=> {
    return (

        <div className={'blockbcg'}>
            <a name="home"> </a>
            <div className={'about'}>
                <h2>
                    О компании
                </h2>
                <p>
                    Аутсорсинг в условиях существующих экономических реалий является
                    эффективным способом выгодно решить кадровое обеспечение предприятия.
                    Услуга по предоставлению персонала опытным агентом позволит избежать рисков
                    непрофессионализма. Крупным компаниям аутсорсинг линейного персонала позволяет
                    свободно развиваться, не тратясь на организацию второстепенных рабочих процессов.
                </p>
                <p>
                    Компания Трудовые ресурсы занимается аутсорсингом персонала в Москве и регионах РФ.
                    Сегодня мы имеем государственную аккредитацию в качестве частного агентства занятости и статус федерального оператора аутсорсинга.
                    Компетентность компании в подборе сотрудников подтверждает соответствие наших ресурсов жестким требованиям государства и современного рынка труда.
                </p>
            </div>
        </div>
    );
};

export default About;