import React from 'react';
import docker from '../img/trphoto/docker.png';
import waiter from '../img/trphoto/waiter.png';
import cleaner from '../img/trphoto/cleaner.png';
import MyButton from "../UI/Buttons/MyButton";
const Human =()=> {
    return (
        <div className={'vacancy'}>
            <a name="human"> </a>
            <div>
                <h2>
                    Персонал
                </h2>
            </div>
            <div className={'CardsBlock'}>
                <div className={'humanCards'}>
                    <img className={'humanImgs'} src={docker} alt=""/>
                    <div>
                        <h3 className={'marginCards'}>Грузчик</h3>
                        {/*<p className={'marginCards'}>200 руб/час*/}
                        {/*</p>*/}
                        <div className={'marginCards'}>
                            <a className={'a'} href="tel:+79037954574">
                                <MyButton className={'MyBtn'}>Позвонить</MyButton>
                            </a>
                    </div>
                    </div>
                </div>
                <div className={'humanCards'}>
                    <img className={'humanImgs'} src={waiter} alt=""/>
                    <div>
                        <h3 className={'marginCards'}>Официант</h3>
                        {/*<p className={'marginCards'}>300 руб/час*/}
                        {/*</p>*/}
                        <div className={'marginCards'}>
                            <a className={'a'} href="tel:+79037954574">
                            <MyButton className={'MyBtn'}>Позвонить</MyButton>
                        </a>
                        </div>
                    </div>
                </div>
                <div className={'humanCards'}>
                    <img className={'humanImgs'} src={cleaner} alt=""/>
                    <div>
                        <h3 className={'marginCards'}>Уборщик</h3>
                        {/*<p className={'marginCards'}>190 руб/час*/}
                        {/*</p>*/}
                        <div className={'marginCards'}>
                            <a className={'a'} href="tel:+79037954574">
                                <MyButton className={'MyBtn'}>Позвонить</MyButton>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Human;