import React from 'react';
import headerImg from "../img/trphoto/headerImg.png"

const Header =()=> {
        return (
                <div className={'block'}>

                    <div className={'titleHed'}>
                        <h1 className={'hedHed'}>
                            Аутсорсинг и лизинг персонала
                        </h1>
                        <p className={'hedText'}>
                            Мы оказываем услуги продовольственному ритейлу и транспортному бизнесу
                        </p>
                    </div>

                    <div>
                        <img style={{width: '100%'}} src={headerImg} alt="Рукопожатие"/>
                    </div>
                </div>
        );
};

export default Header;