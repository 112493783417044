import React from 'react';
import checkMap from "../img/icon/checkMap.svg";
import mail from "../img/icon/email.svg";
import phone from "../img/icon/phone.svg";
import map from "../img/trphoto/map.png";
import MyButton from "../UI/Buttons/MyButton";

const Contacts =()=> {
    return (
        <div className={'blockHand'}>
            <div className={'advanCard'} style={{width:'400px'}}>
            <h2>Контакты</h2>
            <div className={'contCard'}>
                <div style={{marginRight:'8px'}}> <a className={'a'} href="mailto:info@tr-res.ru">
                    <img src={mail} alt="логотип писмо"/>
                </a>
                </div>
                <a className={'a advanText'} href="mailto:workforce.kmosiyash@mail.ru">workforce.kmosiyash@mail.ru</a>
            </div>
            <div className={'contCard'}>
                <div style={{marginRight:'8px'}}><a className={'a'} href="tel:+79037954574"><img src={phone} alt=""/>
                </a></div>
                <a className={'a advanText'} href="tel:+79037954574">8 (903) 795-45-74</a>
            </div>
            <div className={'contCard'}>
                <div style={{marginRight:'6px'}}><img src={checkMap} alt=""/>
                </div>
                <p className={'advanText'}>г. Москва, Конгресс Центр ЦМТ,
                    Краснопресненская наб., 12, стр. 2
                </p>
            </div>
                <div className={'contCard'}>
                <a className={'a'} href="tel:+79037954574">
                    <MyButton className={'MyBtn'}>Позвонить</MyButton>
                </a>
                </div>
            </div>
            <div>
                <img className={'mapImg'}  src={map} alt="Карта"/>
            </div>
        </div>
    );
};

export default Contacts;