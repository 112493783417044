import '../App.css';
import React from 'react';
import logo from '../img/icon/wflogo.svg';
import mail from '../img/icon/email.svg';
import phone from '../img/icon/phone.svg';
import MyButton from "../UI/Buttons/MyButton";

const AppHeader =()=> {

        return (
            <div className={'header'}>
                 <img src={logo} alt="Трудовые ресурсы Логотип"/>
                <div>
                    <div style={{paddingBottom: '16px'}}>
                        <a className={'a'} href="tel:+79037954574"><img src={phone} alt=""/></a>
                        <a className={'a noneMob'} href="tel:+79037954574">8 (903) 795-45-74</a>
                    </div>
                    <div>
                        <a className={'a'} href="mailto:info@tr-res.ru"><img src={mail} alt="логотип писмо"/></a>
                        <a className={'a noneMob'} href="mailto:workforce.kmosiyash@mail.ru">workforce.kmosiyash@mail.ru</a>
                    </div>
                </div>
                <div className={'noneMob'}>
                    <p>Аутсорсинг и лизинг персонала</p>
                    <p>Ежедневно  с 10:00 до 21:00</p>
                </div>
                <a className={'a noneMob'} href="tel:+79037954574">
                <MyButton className={'MyBtn'}>Позвонить</MyButton>
                </a>
            </div>
        );
    };

export default AppHeader;