import React from 'react';
import gallery from '../img/trlogo/gallery.png';
import balchug from '../img/trlogo/balchug.png';
import barvikha from '../img/trlogo/barvikha.png';
import haumin from '../img/trlogo/haumin.png';
import intercon from '../img/trlogo/intercontinental.png';
import landspace from '../img/trlogo/landscape.png';
import marriottb from '../img/trlogo/marriottB.png';
import marrottm from '../img/trlogo/marriottM.png';
import metropol from '../img/trlogo/metropol.png';
import novotel from '../img/trlogo/novotel.png';
import sRain from '../img/trlogo/2.jpg';
import petr1 from '../img/trlogo/petr1.png'

const Partners =()=> {
    return (
        <div className={'vacancy'}>
            <a name="partners"> </a>
            <div>
                <h2>
                    Партенры
                </h2>
            </div>
            <div className={'CardsBlock'} style={{marginTop:'3rem'}}>
                <img className={'logoPart'} src={gallery} alt="Галлери"/>
                <img className={'logoPart'} src={balchug} alt="Балчуг"/>
                <img className={'logoPart'} src={barvikha} alt="Барвиха"/>
                <img className={'logoPart'} src={haumin} alt="Хаумин"/>
                <img className={'logoPart'} src={intercon} alt="Интерконтиненталь"/>
                <img className={'logoPart'} src={landspace} alt="ХолидейИн"/>
                <img className={'logoPart'} src={marriottb} alt="Марриотт Будапешт"/>
                <img className={'logoPart'} src={marrottm} alt="Марриотт Роял Аврора Москва"/>
                <img className={'logoPart'} src={metropol} alt="Метрополь"/>
                <img className={'logoPart'} src={novotel} alt="Новотель"/>
                <img className={'logoPart'} src={petr1} alt="Петр1"/>
                <img className={'logoPart'} src={sRain} alt="Серебряный дождь"/>
        </div>
        </div>
    );
};

export default Partners;